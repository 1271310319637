<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        </b-row>
      </div>

      <b-table
      
      data-testid="faqs-table"
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->
        <template #cell(answer)="data">
          <div v-html="data.item.answer"></div>
          </template>

        <template #cell(actions)="data">
          <a>
            <router-link
              :to="`/update-faq-ngos/${data.item.id}/${id}`"
              style="margin: 0px 12px"
              :data-testid="`edit-item-${data.item.id}`"

            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>

         <a
            v-if="$can('delete', 'faq')"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" 
            :data-testid="`delete-item-${data.item.id}`"
            />
          </a> 
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalaccreditations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

import { useRouter } from '@core/utils/utils'


// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,

    vSelect,
  },
  setup() {
    const accreditationsList = ref([])
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const totalaccreditations = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const sort = ref('')
    const SortArray = []

    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { label: ' question', key: 'question', sortable: true },
      { key: 'answer', key: 'answer', sortable: true },

      { key: 'actions' },
    ]
  
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalaccreditations.value,
      }
    })
    const meta = reactive({})
    const { route } = useRouter()
    const { id } = route.value.params
    const getaccreditations = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }

      store
        .dispatch('setting/AllFaqNgo', {
          // "filter[search]": searchQuery.value,
id,
          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          console.log(response)
          accreditationsList.value = response.data.faqs
          // totalaccreditations.value = response.data.meta.total
        })
    }


    watch([currentPage, perPage, sort], () => {
      getaccreditations()
    })
    getaccreditations()
    const DeleteItem = FaqId => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store
            .dispatch('setting/DeleteNgoFaq', {FaqId, id})
            .then(response => {
              getaccreditations()
              Vue.swal({
                title: ' Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    return {
      id,
      tableColumns,
      accreditationsList,
      DeleteItem,
      meta,
      getaccreditations,
      perPageOptions,
      totalaccreditations,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      SortArray,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
